<template>
  <div>
    <TitleWrapper
      :filter-option="false"
      title="STUDENT_DISCOUNT_DETAIL"
      tooltip-title="STUDENT_DISCOUNT_DETAIL"
      :display-breadcrumb="true"
    />

    <div class="main-content">
      <div v-if="!isLoading">
        <NoRecordFound v-if="!studentDiscountListCount" />
        <div v-else>
          <div
            class="flex flex-wrap bg-primary-white pt-5 px-4 pb-0 border border-primary-gray-200 rounded-lg"
          >
            <div class="w-2/4">
              <div class="flex font-roboto font-base mb-4">
                <div class="w-40 mr-2">First Name:</div>
                <div class="text-menu capitalize">
                  {{
                    (currentStudentDetail && currentStudentDetail.first_name) ||
                    GENERAL_CONSTANTS.NOT_APPLICABLE
                  }}
                </div>
              </div>
              <div class="mt-5 flex font-roboto font-base mb-4">
                <div class="w-40 mr-2">Last Name:</div>
                <div class="text-menu">
                  {{
                    (currentStudentDetail && currentStudentDetail.last_name) ||
                    GENERAL_CONSTANTS.NOT_APPLICABLE
                  }}
                </div>
              </div>
              <div class="mt-5 flex font-roboto font-base mb-4">
                <div class="w-40 mr-2">Total Discount:</div>
                <div class="text-menu">
                  {{ currentStudentDetail && currentStudentDetail.total_discount }}
                </div>
              </div>
            </div>
          </div>
          <div class="text-2xl mt-8">Records</div>
          <TableWrapper>
            <THead>
              <TRHead>
                <TH v-for="head in tableHeads" :key="head">
                  {{ head }}
                </TH>
                <TH v-permission="campusLevelPermisions">Class</TH>
                <TH v-permission="campusLevelPermisions">Section</TH>
              </TRHead>
            </THead>
            <TBody>
              <TRBody v-for="(student, index) in studentDiscountList" :key="index">
                <TD>
                  {{ student.fee_amount || GENERAL_CONSTANTS.NOT_APPLICABLE }}
                </TD>
                <TD>
                  <span v-if="student.discount_given_by_user">
                    <UiBadgeDisplay
                      :options="student.discount_given_by_user"
                      label="full_name"
                      :is-object-concated="false"
                      :image="true"
                      class="pl-8"
                    />
                  </span>
                  <span v-else>Discount Auto Generated</span>
                </TD>
                <TD>
                  {{ student.discount_amount || GENERAL_CONSTANTS.AMOUNT_ZERO }}
                </TD>
                <TD>
                  {{
                    (student.discount_type && student.discount_type.title) ||
                    GENERAL_CONSTANTS.NOT_APPLICABLE
                  }}
                </TD>
                <TD>
                  {{ student.fine_amount || GENERAL_CONSTANTS.AMOUNT_ZERO }}
                </TD>
                <TD>
                  {{
                    (student.fee_type && student.fee_type.title) || GENERAL_CONSTANTS.NOT_APPLICABLE
                  }}
                </TD>
                <TD>
                  {{ student.currency || GENERAL_CONSTANTS.NOT_APPLICABLE }}
                </TD>
                <TD>
                  {{ student.discount_comment || GENERAL_CONSTANTS.NOT_APPLICABLE }}
                </TD>
                <TD>
                  {{ $filters.formattedDate(student.due_date) }}
                </TD>
                <TD v-permission="campusLevelPermisions">
                  <UiBadgeDisplay :options="student.section.class" label="title" class="pl-8" />
                </TD>
                <TD v-permission="campusLevelPermisions">
                  <UiBadgeDisplay :options="student.section" label="title" class="pl-8" />
                </TD>
              </TRBody>
            </TBody>
          </TableWrapper>
        </div>
      </div>

      <div
        v-else
        class="mt-28 h-80 pb-36 bg-primary-white p-5 border border-primary-grey rounded-lg"
      >
        <Loader :content="true" />
      </div>
      <Pagination
        v-if="studentDiscountListCount > 10"
        v-show="!isLoading"
        :record-limit="filteredRecordLimit"
        :max-range="paginationCounts(studentDiscountListCount, filteredRecordLimit)"
        @filterRecord="fetchStudentDetail"
      />
    </div>
  </div>
</template>

<script>
import TitleWrapper from '@components/TitleWrapper.vue'
import { mapActions, mapState } from 'vuex'
import Loader from '@components/BaseComponent/Loader.vue'
import GeneralMixin from '@src/mixins/general-mixins.js'
import Pagination from '@components/BaseComponent/Pagination.vue'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import { getTimeFromDateWithUTC } from '@utils/moment.util'
import TIME_CONSTANTS from '@src/constants/date-time-constants'
import UiBadgeDisplay from '@src/components/UiElements/UiBadgeDisplay.vue'
import NoRecordFound from '@components/BaseComponent/NoRecordFound.vue'
import { findCurrencyById } from '@utils/generalUtil'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'

export default {
  components: {
    TitleWrapper,
    Loader,
    Pagination,
    UiBadgeDisplay,
    NoRecordFound,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },

  mixins: [GeneralMixin],
  data() {
    return {
      TIME_CONSTANTS,
      dashboard: 'dashboard',
      campusLevelPermisions: {
        basedOn: ['campus-level'],
      },
      studentDiscountListCount: 0,
      filteredRecordLimit: GENERAL_CONSTANTS.RECORD_LIMIT,
      GENERAL_CONSTANTS,
      tableHeads: [
        'Fee Amount',
        'Discount Given By',
        'Discount Amount',
        'Discount Type',
        'Fine',
        'Fee Type',
        'Currency',
        'Comment',
        'Due Date',
      ],
      detailModal: false,
      currentStudentDetail: {},
      studentDiscountList: [],
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.layout.isLoading,
      currentCampusScope: (state) => state.layout.currentCampusScope,
      currentSectionScope: (state) => state.layout.currentSectionScope,
    }),
    currencySymbol() {
      return findCurrencyById()?.symbol
    },
  },
  watch: {
    currentSectionScope: {
      handler() {
        this.fetchStudentDetail()
      },
    },
    currentCampusScope: {
      handler(val) {
        if (val) this.fetchStudentDetail()
      },
    },
  },
  /**
   * Mounted hook
   * @description Setting static right bar data
   */
  mounted() {
    this.fetchStudentDetail()
    this.setRightBar()
  },
  methods: {
    async fetchStudentDetail(range) {
      const payload = {
        skip: range?.skip || this.GENERAL_CONSTANTS.RECORD_SKIP,
        limit: range?.limit || this.GENERAL_CONSTANTS.RECORD_LIMIT,
        student_id: this.$route.params.id,
      }
      const [res, err] = await this.getStudentDiscountDetail(payload)
      if (!err) {
        this.currentStudentDetail = res.data.student
        this.studentDiscountList = res.data.records.map((res) => {
          if (res.discount_given_by_user) {
            res.discount_given_by_user.full_name = `${res.discount_given_by_user.first_name} ${res.discount_given_by_user.last_name}`
          }
          return res
        })
        this.studentDiscountListCount = res.data?.meta?.total_records
        this.setRightBar(res.data.student.total_discount)
      }
    },

    setRightBar(data) {
      const rightBarContent = {
        header: {
          title: 'STUDENT_DISCOUNT_DETAIL',
        },
        stats: [
          {
            title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
            categories: [{ text: 'DISCOUNT', value: data, currency: this.currencySymbol }],
          },
        ],
      }
      this.setRightbarContent(rightBarContent)
    },

    ...mapActions('fee', ['getStudentDiscountDetail']),
    ...mapActions('layout', ['setRightbarContent']),
  },
}
</script>

<style lang="scss" scoped>
tr:nth-child(even) {
  background-color: var(--bg-color-light);
}
.showModal-body tr:nth-child(even) {
  background-color: inherit;
}
</style>
